<template>
  <v-data-table
    :headers="headers"
    :items="acronymList"
    :items-per-page="15"
    item-key="name"
    class="elevation-1"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus'
    }"
    :search = "search"
  >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-layout>
                    <v-btn 
                        text
                        color="primary"
                        small
                        >
                        <DashboardAddAcronym/>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-flex md4 class="search">
                        <v-text-field
                            v-model="search"
                            placeholder="Search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field> 
                    </v-flex>
                </v-layout>
            </v-toolbar>
        </template>
        
        <template v-slot:[`item.action`]="{item}">
            <v-btn 
                text
                color="red"
                small
                >
                <DashboardEditAcronym :item="item"> </DashboardEditAcronym>
            </v-btn>
            <v-btn 
                text
                color="red"
                small
                >
                <DashboardDeleteAcronym :item="item"> </DashboardDeleteAcronym>
            </v-btn>
        </template>

  </v-data-table>
</template>


<script>
import DashboardEditAcronym from '../components/modules/salesProspecting/DashboardEditAcronym.vue';
import DashboardAddAcronym from '../components/modules/salesProspecting/DashboardAddAcronym.vue';
import DashboardDeleteAcronym from '../components/modules/salesProspecting/DashboardDeleteAcronym.vue';

export default{
    data () {
        return {
            faqList: null,
            acronymList: [],
            statusList: null,
            deleteId: '',
            search: '',
            headers: [
            {
                text: 'Title',
                align: 'start',
                value: 'title',
            },
            { text: 'Description', value: 'description' },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
            ],
        }
    },
    components:{
      DashboardEditAcronym: DashboardEditAcronym,
      DashboardAddAcronym: DashboardAddAcronym,
      DashboardDeleteAcronym: DashboardDeleteAcronym,
    },
    async mounted () {
      const res = await this.$store.dispatch('salesProspecting/doGetDashboardData')
      this.assigndata(res.data.result)
    },
	methods:{
		assigndata(data){
            this.acronymList = data.dashboard_acronym
		},
	}
}
</script>
