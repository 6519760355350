<template>
  <v-row justify="center">
    <v-dialog
      v-model="dashboardAddDialog"
      width="900px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            small
            >
            <v-icon>mdi-plus</v-icon>
            </v-btn>
      </template>
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">Add Acronym</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <h4 class="mb-2">Title:</h4>
                            <v-text-field
                                placeholder="Add Title"
                                auto-grow
                                v-model="newTitle"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <h4 class="mb-2">Description:</h4>
                    <v-row>
                        <v-col>
                            <v-textarea
                                auto-grow
                                outlined
                                v-model="newDesc"
                            placeholder="Add Description"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <section>
                          <v-btn
                            v-if="newTitle && newDesc"
                            class="mr-4"
                            color="primary"
                            style="float:right;"
                            @click="add"
                            >
                            <b>Add</b>
                          </v-btn>
                          <v-btn
                            v-else
                            class="mr-4"
                            color="primary"
                            style="float:right;"
                            disabled
                            >
                            <b>Add</b>
                          </v-btn>
                        </section>
                        <section >
                          <v-btn
                            class="mr-4"
                            outlined
                            color="primary"
                            style="float:left;"
                            @click="cancel"
                            >
                            <b>Cancel</b>
                          </v-btn>
                        </section>
                      </v-col>
                    </v-row>
              </v-container>
          </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data () {
      return {
        newTitle: null,
        newDesc: null,
        dashboardAddDialog: false,
      }
    },
    methods:{
        cancel(){
          this.dashboardAddDialog = false
          this.newTitle = '',
          this.newDesc = ''
        },
         async add(){
            const payload = {
                title: this.newTitle,
                description: this.newDesc
            }
            console.log(payload)
            const res = await this.$store.dispatch('salesProspecting/doAddAcronym', {payload})
                if (res.status === 200){
                    this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Added Successfully!','messagetype':'success'});
                    this.dashboardAddDialog = false
                    location.reload();
                }
        },
    },
  }
</script>

<style scoped>
    #dialog_title{
        color: rgb(20, 20, 137);
        font-weight: 600;
    }
</style>