<template>
    <v-row justify="center">
        <v-dialog
            v-model="deleteDialog"
            width="500px"
            persistent
            >
            <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="red"
                    text
                    v-bind="attrs"
                    v-on="on"
                    small
                    >
                    <v-icon>mdi-delete</v-icon>
                    </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    Confirmation
                </v-card-title>
                <v-card-text>
                    <p style="font-size: 15px;">Are you sure you want to delete {{item.title}}?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="cancelDialog"
                        >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        @click="confirmDelete"
                        >
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

export default {
    props:['item'],
    data () {
        return {
            deleteDialog: false,
        }
    },
    methods: {
        cancelDialog(){
            this.deleteDialog = false
        },
        async confirmDelete(){
            const res = await this.$store.dispatch('salesProspecting/doDeleteAcronym', this.item.id)
            if (res.status === 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Deleted Successfully!','messagetype':'success'});
                this.deleteDialog = false
                location.reload();
            }
        },
    }
}
</script>
