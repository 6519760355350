var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.acronymList,"items-per-page":15,"item-key":"name","footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-minus',
    nextIcon: 'mdi-plus'
  },"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-layout',[_c('v-btn',{attrs:{"text":"","color":"primary","small":""}},[_c('DashboardAddAcronym')],1),_c('v-spacer'),_c('v-flex',{staticClass:"search",attrs:{"md4":""}},[_c('v-text-field',{attrs:{"placeholder":"Search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:`item.action`,fn:function({item}){return [_c('v-btn',{attrs:{"text":"","color":"red","small":""}},[_c('DashboardEditAcronym',{attrs:{"item":item}})],1),_c('v-btn',{attrs:{"text":"","color":"red","small":""}},[_c('DashboardDeleteAcronym',{attrs:{"item":item}})],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }